<template>
  <div class="videos__wrapper">
    <div class="container">
      <h3>Fanclubtreffen 2024</h3>
      <div class="videos__content mx-auto mb-4">
        <div class="videos__embed">
          <b-embed
            type="iframe"
            src="https://www.youtube.com/embed/UeFQy9iSLvM?si=2fDRUt5l7UJttE8w"
            allowfullscreen
          />
        </div>
      </div>

      <h3>Musikantenfest 2023</h3>
      <div class="videos__content mx-auto">
        <div class="videos__embed">
          <b-embed
            type="iframe"
            src="https://www.youtube.com/embed/8sTR7zVUA28?si=7IwUMdpa4I9RjAyT"
            allowfullscreen
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Videos",
};
</script>
